

















































import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    username: '',
    password: '',
    loading: false,
  }),
  methods: {
    login() {
      const { username, password } = this;
      this.loading = true;
      // @ts-ignore
      this.$auth
        .login({
          data: { username, password },
          redirect: { name: 'dashboard' },
          staySignedIn: true,
          fetchUser: true,
        })
        .then(() => {
          this.$store.commit('GET_USER_INFORMATION');
        })
        .catch(({ response }) => {
          if (response?.status === 422) {
            const message: string = Object.values<string>(response.data.errors).shift()
              ?? 'Formulário possui dados inválidos';
            this.$swal({
              icon: 'error',
              title: 'Oops...',
              text: message,
            });
          } else {
            this.$swal({
              icon: 'error',
              title: 'Oops...',
              text: 'Falha ao realizar o login, tente novamente',
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
